@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@font-face {
  font-family: 'Montserrat Regular';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat Medium';
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat Black';
  src: url('./fonts/Montserrat-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Europa';
  src: url('//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.eot');
  src: url('//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.svg#Europa')
      format('svg');
}

* {
  font-family: 'Montserrat Regular', sans-serif;
}

a,
button {
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  outline: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body p {
  color: #4c5b69;
}

.mb-6 {
  margin-bottom: 6rem;
}
.mb-7 {
  margin-bottom: 7rem;
}
.mb-10 {
  margin-bottom: 10rem;
}
.mb-15 {
  margin-bottom: 15rem;
}
.mb-20 {
  margin-bottom: 20rem;
}
.mt-10 {
  margin-top: 10rem;
}
.mt-15 {
  margin-top: 15rem;
}
.mt-20 {
  margin-top: 20rem;
}
.p-0 {
  padding: 0;
}

/* Tooltip */
.tooltip div.tooltip-inner {
  background-color: #9a9a9a;
  max-width: 420px;
  box-shadow: 0 0px 10px 0 rgba(39, 39, 39, 0.29);
}
.tooltip span.arrow {
  display: none !important;
}

/* Modal Video */
button.modal-video-close-btn {
  right: -2px;
  width: 26px;
  height: 34px;
}
@media (min-width: 1025px) {
  button.modal-video-close-btn {
    right: -30px;
  }
}
button.modal-video-close-btn:hover {
  opacity: 0.7;
}
button.modal-video-close-btn:before,
button.modal-video-close-btn:after {
  height: 4px;
  border-radius: 10px;
}

/* Modal Bootstrap */
.modal .modal-header {
  border: none;
}
.modal-header button.close {
  position: absolute;
  padding: 0;
  opacity: 1;
  right: 13px;
  top: -20px;
  width: 26px;
  height: 34px;
  z-index: 2;
  display: inline-block;
  overflow: hidden;
  border: none;
  background: transparent;
}
@media (min-width: 841px) {
  .modal-header button.close {
    right: -30px;
    top: -3px;
  }
}
.modal-header button.close span {
  display: none;
  color: white;
  font-size: 39px;
}

.modal-header button.close:before,
.modal-header button.close:after {
  content: '';
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

.modal-header button.close:before {
  transform: rotate(45deg);
}

.modal-header button.close:after {
  transform: rotate(-45deg);
}
.modal .modal-body {
  padding: 1rem 2rem;
}
.modal .modal-dialog {
  top: 4rem;
  max-width: unset;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .modal .modal-dialog {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .modal .modal-body {
    padding: 1rem 4rem;
  }
  .modal .modal-dialog {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .modal .modal-dialog {
    max-width: 920px;
  }
}

/* Product Modal */
button.SRLAutoplayButton,
button.SRLExpandButton {
  display: none;
}

.SRLElementContainer {
  background: white;
  height: 100% !important;
  width: 100% !important;
  max-width: 900px;
  max-height: 480px;
}

button.SRLCloseButton {
  background: none;
  width: 50px;
  height: 50px;
}
button.SRLCloseButton div svg path {
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  fill: white;
  stroke: white;
  stroke-width: 2px;
}
button.SRLCloseButton div:hover svg path {
  fill: white;
  opacity: 0.7;
}

@media (min-width: 769px) {
  div.css-ycfl2y {
    top: 6%;
    right: 10%;
  }
}

.SRLThumbnails {
  margin-bottom: auto;
  background: white;
  width: 100% !important;
  max-width: 900px;
  height: 110px !important;
  justify-content: center !important;
}
.SRLCaption {
  display: none;
}

button.SRLPrevButton,
button.SRLNextButton {
  background-color: transparent;
  border: 1px solid #979797;
  border-radius: 100%;
  display: none;
}
button.SRLPrevButton svg path,
button.SRLNextButton svg path {
  fill: #0b96b8;
  stroke-width: 3px;
  stroke: #0b96b8;
}
button.SRLPrevButton {
  bottom: 8rem;
  left: 16%;
  top: unset;
  right: unset;
}
button.SRLNextButton {
  bottom: 8rem;
  right: 16%;
  top: unset;
  left: unset;
}
.SRLDownloadButton {
  display: none !important;
}

/* Customized Input Range */
.input-range--disabled {
  opacity: 0.7;
}
.input-range--disabled div.input-range__track {
  cursor: not-allowed;
}
.input-range--disabled div.input-range__slider,
.input-range--disabled div.input-range__track--active {
  background: #0b96b8;
  border-color: #0b96b8;
  cursor: not-allowed;
}
div.input-range__track--active {
  background: #0b96b8;
}
div.input-range__slider {
  background: #0b96b8;
  border-color: #0b96b8;
}
.hidden-range-min-max span.input-range__label--min,
.hidden-range-min-max span.input-range__label--max {
  display: none;
}

.hidden-value span.input-range__label-container {
  display: none;
}

span.input-range__label--min,
span.input-range__label--max {
  font-family: 'Montserrat Regular';
  font-size: 15px;
  color: #4c5b69;
  top: -1.5rem;
}
span.input-range__label--min {
  left: 4px;
}
span.input-range__label--max {
  right: 4px;
}
span.input-range__label--value {
  font-family: 'Montserrat Regular';
  font-size: 20px;
  color: #4c5b69;
  top: -2.5rem;
}
div.input-range__track {
  height: 6px;
}
.zero-opacity {
  opacity: 0;
}
.untouchable {
  position: absolute;
  z-index: -1;
}
div.input-range__track--background {
  z-index: 2;
}